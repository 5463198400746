import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useEffect } from "react";
// import optimizeImageSize from "../../../../utils/optimizeImageSize";
import { productsActionCreators } from "../../../../redux/products";
import formatter from "../../../../utils/formatter";
import { optimizeImageSizeAndRemoveBackground } from "../../../../utils/backgroundRemoval";

const Bestseller = ({ products }) => {
  const navigate = useNavigate();

  // const { type } = useSelector((state) => state.user);
  const { data: currencyData } = useSelector((state) => state.currency);

  const { homepageProducts } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const { fetchWomenHomepageProductsAction } = bindActionCreators(
    productsActionCreators,
    dispatch
  );

  useEffect(() => {
    fetchWomenHomepageProductsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bestseller-container">
      <div className="bestseller-head">
        <div className="bestseller-title">This Season, Make It Yours</div>
        <p className="bestseller-subtext">
          <b>Style is more than what you wear, it’s how you show up</b> - Bold.
          Unapologetic. Unfiltered. Whether you are power-dressing for
          daylight or slipping into something unforgettable after dark, this is
          your moment to experiment, express, and evolve
        </p>
      </div>

      <div className="bestseller-products-container">
        {homepageProducts?.bestsellers?.map((product) => (
          <div
            className="bestseller-product"
            key={product.img1}
            onClick={() => navigate(`/designers/${product?.store_slug}`)}
          >
            <img
              className="bestseller-product-image"
              loading="lazy"
              alt="product"
              src={optimizeImageSizeAndRemoveBackground(product.img1)}
              style={{ backgroundColor: "#E9E8E4" }}
            />
            <div
              style={{ textAlign: "left", fontFamily: "DomaineSansText-Light" }}
            >
              <div className="bestseller-product-title">
                {product.store_name}
              </div>
              <div className="bestseller-product-subtitle">{product.name}</div>
            </div>
            <div className="bestseller-btn-price-contain">
              {/* <Button
                className="bestseller-btn"
                // onClick={() => shopNewIn()}
              >
                Buy Now
              </Button> */}
              <span className="bestseller-btn">Buy Now</span>
              <div className="bestseller-product-price">
                {formatter(
                  product?.price,
                  currencyData?.rate,
                  currencyData?.code,
                  currencyData?.indicator
                ).replace(/\.00$/, "")}
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .bestseller-container {
          margin: 0 auto;
          padding: 30px 4rem;
          background: #f9f9f9;
        }
        .bestseller-head {
          margin-bottom: 25px;
        }
        .bestseller-container .bestseller-title {
          font-family: DomaineSansText-Medium;
          font-size: 30px;
          text-align: center;
          line-height: 30px;
          // letter-spacing: -0.7px;
          margin: 0 auto 16px;
          font-weight: 600 !important;
        }
        .bestseller-subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 1000px;
          margin: 8px auto 16px;
          text-align: center
        }
        .bestseller-products-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
              0,
              1fr
            );
          gap: 24px;
          justify-content: center;
          text-align: center;
        }
        .bestseller-product {
          background: #fff;
          border-radius: 8px;
          padding: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .bestseller-product-image {
          width: 100%;
          height: auto;
          aspect-ratio: 11/16;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .bestseller-product-title {
          font-size: 16px;
          font-family: DomaineSansText-Medium;
          line-height: 21px;
          margin: 14px 0px 4px;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .bestseller-product-subtitle {
          font-size: 14px;
          font-family: DomaineSansText-Regular;
          color: #000;
          line-height: 1;
          margin: 0px 0px 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .bestseller-product-price {
          font-size: 14px;
          font-family: DomaineSansText-Medium;
          color: #000;
        }
        .bestseller-btn-price-contain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
        }
        .bestseller-btn {
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #000;
          font: normal normal 14px DomaineSansText-Regular;
        }
      `}</style>
    </div>
  );
};

export default Bestseller;
