import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const RandomCategory = () => {
  const navigate = useNavigate();

  return (
    <div className="randomcategory-container">
      <img
        // src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732096365/website-pictures-optimized/random-cat-img_rydwaq.png"
        src="https://res.cloudinary.com/losode-ng/image/upload//f_auto/v1744104037/website-pictures-optimized/random_catgeory_ftf6ke.jpg"
        alt="random-category"
        className="randomcategory-image"
        height={600}
        width={1000}
      />
      <div className="randomcategory-content-container" style={{zIndex: "1000"}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="txt-style-main">
            Contemporary Style with a Touch of Tradition
          </div>
          <div className="subtext">
            Discover our collection of elegant Boubous that seamlessly blend
            modern style with timeless tradition
          </div>
          <Button
            className="btn-shop-randomcategory"
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/products/category/cid=23&name=women-bou-bous?page=1")}
          >
            Shop BouBous
          </Button>
        </div>
      </div>
      <div className="randomcategory-content-container-overlay"></div>
      <style jsx="true">{`
        .randomcategory-container {
          position: relative;
          background-position: top;
          margin: 20px 4rem;
          padding: 0px 0;
        }
        .randomcategory-image {
          width: 100%;
          height: 100%;
        }
        .randomcategory-container .btn-shop-randomcategory {
          width: 350px;
          height: 48px;
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 16px DomaineSansText-Light;
        }
        .randomcategory-content-container {
          position: absolute;
          right: 50%;
          transform: translateX(50%);
          bottom: 5%;
          color: #fff;
          width: 100%;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
        }
        .randomcategory-content-container-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
        }
        .txt-style-main {
          font-family: DomaineSansText-Medium;
          font-size: 30px;
          font-weight: 600;
          line-height: 40px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          text-transform: uppercase;
        }

        .randomcategory-content-container .subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          max-width: 470px;
          text-align: center;
        }
        .text-style-premain {
          font-size: 24px;
          font-family: DomaineSansText-Light;
          color: #fff;
          text-transform: uppercase;
        }

        @media (max-width: 640px) {
          .randomcategory-container {
            height: 248px;
            margin: 0px 16px;
          }
          .randomcategory-content-container {
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .randomcategory-container .btn-shop-randomcategory {
            width: 208px;
            height: 40px;
            font-size: 14px;
          }
          .randomcategory-content-container .subtext {
            font-size: 14px;
          }
          .text-style-premain {
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default RandomCategory;
